main::-webkit-scrollbar {
  display: none;
  width: 300px
}

input[type=number]::-webkit-outer-spin-button {
  display: none;
}

input[type=number]::-webkit-inner-spin-button {
  display: none;
}

.amplify-tabs-item[data-state=inactive] {
  color: grey;
  border-color: black;
  background-color: #E6E6E6;
  border-radius: 2%;
  transition-property: none;
}